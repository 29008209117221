export const HIDE_PRODUCT_CONFIRM_DRAWER = 'HIDE_PRODUCT_CONFIRM_DRAWER'
export const SET_CATALOG_BRANDS = 'SET_CATALOG_BRANDS'
export const SET_CATALOG_GROUPS = 'SET_CATALOG_GROUPS'
export const SET_CATALOG_MENUS = 'SET_CATALOG_MENUS'
export const SET_CATALOG_PRODUCTS = 'SET_CATALOG_PRODUCTS'
export const SET_CATALOG_SORT = 'SET_CATALOG_SORT'
export const SET_CATALOG_SPECIES = 'SET_CATALOG_SPECIES'
export const SET_CATALOG_STATE_FILTER = 'SET_CATALOG_STATE_FILTER'
export const SET_CATALOG_STRAINS = 'SET_CATALOG_STRAINS'
export const SET_CATALOG_SUBTYPES = 'SET_CATALOG_SUBTYPES'
export const SET_CATALOG_TYPES = 'SET_CATALOG_TYPES'
export const SET_LOCATION_STATES = 'SET_LOCATION_STATES'
export const SET_PRODUCT_CONFIRM_DRAWER_REASONS =
  'SET_PRODUCT_CONFIRM_DRAWER_REASONS'
export const SHOW_PRODUCT_CONFIRM_DRAWER = 'SHOW_PRODUCT_CONFIRM_DRAWER'
export const TOGGLE_ACTION_BAR = 'TOGGLE_ACTION_BAR'
export const HIDE_ACTION_BAR = 'HIDE_ACTION_BAR'
export const SET_CATALOG_ECOMMERCE_FILTER = 'SET_CATALOG_ECOMMERCE_FILTER'
