import api from 'api'

import { setDriverMap, updateDriverMap } from 'src/redux/driver-map/actions'
import { getDriverMap } from 'src/redux/driver-map/selectors'
import { DRIVER_MODES_MAP, getDefaultDriverObjectWithLocation } from 'helpers/drivers'

const PRUNE_DRIVER_DELTA = 90 * 1000

function changeDriverDeliveryType (driverId, deliveryType) {
  return (dispatch, getState) => {
    const state = getState()
    const driverMap = getDriverMap(state)
    const driver = { ...driverMap[driverId] }
    driver.deliveryType = deliveryType

    dispatch(updateDriverMap({ [driver.id]: driver }))
  }
}

export function setDriverDeliveryType (id, deliveryType) {
  return async (dispatch) => {
    const { err } = api.setDriverDeliveryType({ id, deliveryType })
    if (err) return

    dispatch(changeDriverDeliveryType(id, deliveryType))
  }
}

export function changeDriverMode (mode, driverId) {
  return async (dispatch, getState) => {
    const state = getState()
    const driverMap = getDriverMap(state)
    const currentDriver = { ...driverMap[driverId] }

    const { err } = await api.overWriteDriverMode({
      driverMode: mode,
      userId: driverId,
      id: driverId
    })

    if (err) return

    currentDriver.driverMode = mode
    currentDriver.driverModeDescription = DRIVER_MODES_MAP[mode].toUpperCase()

    dispatch(updateDriverMap({ [currentDriver.id]: currentDriver }))
  }
}

export function changeDriverDepotId (driverId, newDepotId) {
  return async (dispatch, getState) => {
    const state = getState()
    const driverMap = getDriverMap(state)
    const currentDriver = { ...driverMap[driverId] }

    currentDriver.depotId = newDepotId

    dispatch(updateDriverMap({ [currentDriver.id]: currentDriver }))
  }
}

export function fetchDriversByDepot (id) {
  return async (dispatch, getState) => {
    const state = getState()
    const { err, data } = await api.getActiveDriversByDepot({ id })
    if (err) return console.error(err)
    if (!data.length) return null // `no active drivers for depot ${id}`

    if (!data[0].depotName) return
    const driverMap = formatDepotDriversMap(data, getDriverMap(state))

    dispatch(updateDriverMap(driverMap))
  }
}

export function formatDepotDriversMap (drivers, currentDriverMap) {
  const updatedTimestamp = Date.now()
  // creates a dictionary of drivers keyed by id by merging the current depot driver objects with the updated ones
  const driverMap = drivers.reduce((accumulator, newDriver) => {
    const currentDriverObj = currentDriverMap[newDriver.id] || getDefaultDriverObjectWithLocation()
    accumulator[newDriver.id] = {
      ...currentDriverObj,
      ...newDriver,
      rehydratedAt: updatedTimestamp
    }
    return accumulator
  }, {})

  return driverMap
}

export function pruneStaleDrivers (focusedDriverId) {
  return async (dispatch, getState) => {
    const state = getState()
    const { driverMap } = state
    const newDriverMap = { ...driverMap }
    const now = Date.now()

    let shouldPrune = false
    const prunedDriverDict = {}
    Object.values(newDriverMap).forEach(driver => {
      const { rehydratedAt, id } = driver
      if (now - rehydratedAt > PRUNE_DRIVER_DELTA) {
        shouldPrune = true
        delete newDriverMap[id]
        // if pruned driver is focused, de-focus that driver
        prunedDriverDict[id] = true
      }
    })

    if (shouldPrune) {
      dispatch(setDriverMap(newDriverMap))
    }

    return prunedDriverDict
  }
}

export const filterDriverMapByDepotId = (driverMap, depotId) => {
  const drivers = Object.values(driverMap)
  return drivers.filter(driver => driver)
}
