import { createSelector } from 'reselect'

import sortOn from 'sort-on'

const getCatalogState = (state) => state.catalog
const getProductsStore = (state) => state.products

const getProducts = (state) => state.catalog.products
const getProductsSort = (state, sortKey) =>
  sortKey || state.catalog.sort.products

const getBrands = (state) => state.catalog.brands
const getBrandsSort = (state, sortKey) => sortKey || state.catalog.sort.brands

const getGroups = (state) => state.catalog.groups
const getGroupsSort = (state, sortKey) => sortKey || state.catalog.sort.groups

const getMenus = (state) => state.catalog.menus
const getMenusSort = (state, sortKey) => sortKey || state.catalog.sort.menus

const getStrains = (state) => state.catalog.strains
const getStrainsSort = (state, sortKey) =>
  sortKey || state.catalog.sort.strains

const getTypes = (state) => state.catalog.types
const getTypesSort = (state, sortKey) => sortKey || state.catalog.sort.types

const getSubtypes = (state) => state.catalog.subtypes
const getSubtypesSort = (state, sortKey) =>
  sortKey || state.catalog.sort.subtypes

export const getCatalogStateFilter = (state) =>
  state.catalog.catalogStateFilter

export const getCatalogEcommerceFilter = (state) =>
  state.catalog.ecommerceFilter

export const getSortedCatalogProducts = createSelector(
  [getProductsStore, getProducts, getProductsSort],
  (productsStore, productIds, productsSort) => {
    const products = productIds.map((id) => productsStore[id])
    // Exclude products that do not have brand property.
    // These are products that were created before Ash and contribute to poor data integrity.
    // eslint-disable-next-line array-callback-return
    const filteredProducts = products.filter((p) => {
      if (p && p.brand) {
        return p
      }
    })
    return sortOn(filteredProducts, productsSort)
  }
)

export const getSortedCatalogBrands = createSelector(
  [getBrands, getBrandsSort],
  (brands, brandsSort) => {
    return sortOn(brands, brandsSort)
  }
)

export const getSortedCatalogGroups = createSelector(
  [getGroups, getGroupsSort],
  (groups, groupsSort) => {
    return sortOn(groups, groupsSort)
  }
)

export const getSortedCatalogMenus = createSelector(
  [getMenus, getMenusSort],
  (menus, menusSort) => {
    if (!menus || !menus.length) return []

    return sortOn(menus, menusSort)
  }
)

export const getSortedCatalogStrains = createSelector(
  [getStrains, getStrainsSort],
  (strains, strainsSort) => {
    if (!strains || !strains.length) return []

    return sortOn(strains, strainsSort)
  }
)

export const getSortedCatalogTypes = createSelector(
  [getTypes, getTypesSort],
  (types, typesSort) => {
    if (!types || !types.length) return []

    return sortOn(types, typesSort)
  }
)

export const getSortedCatalogSubtypes = createSelector(
  [getSubtypes, getSubtypesSort],
  (subtypes, subtypesSort) => {
    if (!subtypes || !subtypes.length) return []

    return sortOn(subtypes, subtypesSort)
  }
)

export const getCatalogTypes = createSelector(
  [getCatalogState],
  (catalog) => catalog.types
)

export const getCatalogSubtypes = createSelector(
  [getCatalogState],
  (catalog) => catalog.subtypes
)

export const getCatalogStrains = createSelector(
  [getCatalogState],
  (catalog) => catalog.strains
)

export const getCatalogSpecies = createSelector(
  [getCatalogState],
  (catalog) => catalog.species
)

export const getCatalogStatesAvailable = createSelector(
  [getCatalogState],
  (catalog) => sortOn(catalog.statesAvailable, 'name')
)

export const getCatalogProductConfirmDrawerReasons = createSelector(
  [getCatalogState],
  (catalog) => catalog.productConfirmDrawerReasons
)

export const getCatalogIsProductFormOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isProductFormOpen
)

export const getCatalogIsTypeFormOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isTypeFormOpen
)

export const getCatalogIsSubtypeFormOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isSubtypeFormOpen
)

export const getCatalogIsStrainFormOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isStrainFormOpen
)

export const getCatalogIsActionBarOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isActionBarOpen
)

export const getCatalogIsProductConfirmDrawerOpen = createSelector(
  [getCatalogState],
  (catalog) => catalog.isProductConfirmDrawerOpen
)

export const getIsBulkCreateActive = createSelector(
  [getCatalogState],
  (catalog) => catalog.isBulkCreateActive
)

export const getCatalogSort = createSelector(
  [getCatalogState],
  (catalog) => catalog.sort
)
