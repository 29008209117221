import React, { PureComponent } from 'react'
import { bool, func, number, object, string } from 'prop-types'
import styled from '@emotion/styled'

import DepotIcon from 'src/microcomponents/icon-depot'
import EmailIcon from 'src/microcomponents/icon-email-filled'
import PhoneIcon from 'src/microcomponents/icon-phone'
import ProfileIcon from 'src/microcomponents/icon-profile'
import {
  CarIcon,
  DriversLicenseIcon,
  VerticalMenuIcon,
  OccupationalLicenseIcon
} from 'src/microcomponents/icons'

import DriverStatus from './driver-status'
import DeliveryTypeSelect from './delivery-type-select'

import { midGray } from 'src/helpers/css-variables'

export default class DriverInfo extends PureComponent {
  static propTypes = {
    changeDriverMode: func,
    deliveryType: string,
    depotDisplayName: string,
    displayName: string,
    driverCurrentVehicle: object,
    driverMETRCInfoDrawerOpen: bool,
    driverMode: number,
    email: string,
    id: number,
    mobilePhoneDisplay: string,
    pictureUrl: string,
    setDriverDeliveryType: func,
    showDriverMETRCInfoDrawerToggle: bool,
    toggleDriverMETRCInfoDrawer: func,
    isUsingServiceAreaDrivers: bool
  }

  static defaultProps = {
    driverCurrentVehicle: {},
    showDriverMETRCInfoDrawerToggle: false,
    isUsingServiceAreaDrivers: false,
    toggleDriverMETRCInfoDrawer: () => {}
  }

  componentWillUnmount () {
    const {
      driverMETRCInfoDrawerOpen,
      toggleDriverMETRCInfoDrawer
    } = this.props
    if (driverMETRCInfoDrawerOpen) {
      toggleDriverMETRCInfoDrawer()
    }
  }

  render () {
    const {
      changeDriverMode,
      deliveryType,
      driverCurrentVehicle,
      driverMode,
      depotDisplayName,
      displayName,
      email,
      id,
      mobilePhoneDisplay,
      pictureUrl,
      setDriverDeliveryType,
      showDriverMETRCInfoDrawerToggle,
      toggleDriverMETRCInfoDrawer,
      isUsingServiceAreaDrivers
    } = this.props

    const {
      driverOccupationalLicenseNumber,
      driversLicenseNumber,
      licensePlateNumber,
      vehicleMake,
      vehicleModel
    } = driverCurrentVehicle

    return (
      <Container className='DriverProfile'>
        <Avatar src={pictureUrl} />
        <Wrapper>
          <DriverNameRow>
            <Name>{displayName}</Name>
            <DriverNameRowControls>
              <DeliveryTypeSelect
                deliveryType={deliveryType}
                driverId={id}
                setDriverDeliveryType={setDriverDeliveryType}
              />
              <HorizontalSpacer />
              <DriverStatus
                changeDriverMode={changeDriverMode}
                driverMode={driverMode}
                driverId={id}
              />
              {showDriverMETRCInfoDrawerToggle && (
                <>
                  <HorizontalSpacer />
                  <ToggleDriverMETRCInfoDrawer
                    onClick={toggleDriverMETRCInfoDrawer}
                  >
                    <VerticalMenuIcon />
                  </ToggleDriverMETRCInfoDrawer>
                </>
              )}
            </DriverNameRowControls>
          </DriverNameRow>
          <DriverContactInfo>
            <ContactItemWrapper>
              <ProfileIcon />
              <Id>{id}</Id>
            </ContactItemWrapper>
            <ContactItemWrapper>
              <PhoneIcon />
              <ContactItem className='fs-block'>
                {mobilePhoneDisplay}
              </ContactItem>
            </ContactItemWrapper>
            <ContactItemWrapper>
              <EmailIcon />
              <ContactItem>{email}</ContactItem>
            </ContactItemWrapper>
          </DriverContactInfo>
          <DriverMETRCInfo
          isUsingServiceAreaDrivers={isUsingServiceAreaDrivers}>
            {depotDisplayName && (
              <ContactItemWrapper>
                <DepotIcon />
                <ContactItem>{depotDisplayName}</ContactItem>
              </ContactItemWrapper>
            )}
            {licensePlateNumber && (
              <ContactItemWrapper>
                <CarIcon />
                <ContactItem>
                  {`${vehicleMake} ${vehicleModel}, Plate: ${licensePlateNumber}`}
                </ContactItem>
              </ContactItemWrapper>
            )}
            {driverOccupationalLicenseNumber && (
              <ContactItemWrapper>
                <OccupationalLicenseIcon />
                <ContactItem>
                  Occ License: {driverOccupationalLicenseNumber}
                </ContactItem>
              </ContactItemWrapper>
            )}
            {driversLicenseNumber && (
              <ContactItemWrapper>
                <DriversLicenseIcon />
                <ContactItem>DL: {driversLicenseNumber}</ContactItem>
              </ContactItemWrapper>
            )}
          </DriverMETRCInfo>
        </Wrapper>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  width: 100%;
`

const DriverNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const DriverNameRowControls = styled.div`
  display: flex;
  justify-content: center;
  max-height: 3.2rem;
`

const HorizontalSpacer = styled.div`
  width: 2.4rem;
`

const ToggleDriverMETRCInfoDrawer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: center;
  cursor: pointer;
`

const Avatar = styled.img`
  border-radius: 50%;
  height: 5.3rem;
  width: 5.3rem;
  margin-right: 2.4rem;
  background-color: ${midGray};
`

const Name = styled.div`
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.03rem;
`

const DriverContactInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.6rem;
`

const DriverMETRCInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`

const Id = styled.div`
  font-size: 1.4rem;
  letter-spacing: 0.03rem;
  margin-left: 0.6rem;
`

const ContactItemWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 2.4rem;
  font-size: 1.6rem;
`

const ContactItem = styled.div`
  font-size: 1.4rem;
  letter-spacing: 0.03rem;
  margin-left: 0.6rem;
`
