// https://github.com/TehShrike/deepmerge
import deepmerge from 'deepmerge'

import {
  HIDE_PRODUCT_CONFIRM_DRAWER,
  SET_CATALOG_BRANDS,
  SET_CATALOG_ECOMMERCE_FILTER,
  SET_CATALOG_GROUPS,
  SET_CATALOG_MENUS,
  SET_CATALOG_PRODUCTS,
  SET_CATALOG_SORT,
  SET_CATALOG_SPECIES,
  SET_CATALOG_STATE_FILTER,
  SET_CATALOG_STRAINS,
  SET_CATALOG_SUBTYPES,
  SET_CATALOG_TYPES,
  SET_LOCATION_STATES,
  SET_PRODUCT_CONFIRM_DRAWER_REASONS,
  SHOW_PRODUCT_CONFIRM_DRAWER
} from 'src/redux/catalog/actionTypes'
import api from 'api'
import gatewayClient from 'src/gateway-client'

import {
  receiveCatalog,
  receiveProducts,
  receiveSpecies,
  requestCatalog,
  requestProducts,
  requestSpecies
} from 'src/redux/loading/actions'

import { getCatalogEcommerceFilter, getCatalogStateFilter } from './selectors'
import { setProduct, setProducts } from 'src/redux/products/actions'
import { arrayToObject } from 'src/redux/products/helpers'
import { AVAILABLE_STATES, SPECIES_CATALOG_TYPE } from 'helpers/constants'
import { clearBrandsMap, setBrandsMap } from 'src/redux/brands-map/actions'
import { clearSpeciesMap, setSpeciesMap } from 'src/redux/species-map/actions'

import { submitCatalogItem } from 'src/redux/catalog/item/actions'
import ROUTES from 'src/pages/catalog/routes'
import { dedupeArrayByObjectKey } from 'src/utils/helpers'

const ENTITY_MAP = {
  brands: {
    fetch: api.getCatalogBrands,
    postFetch: createBrandMap,
    set: setCatalogBrands
  },
  groups: {
    fetch: api.getCatalogGroups,
    set: setCatalogGroups
  },
  menus: {
    fetch: api.getCatalogMenus,
    set: setCatalogMenus
  },
  strains: {
    fetch: api.getCatalogStrains,
    set: setCatalogStrains
  },
  subtypes: {
    fetch: api.getCatalogSubtypes,
    set: setCatalogSubtypes
  },
  types: {
    fetch: api.getCatalogTypes,
    set: setCatalogTypes
  }
}

// map used for canceling in-flight requests by fetch type
const ENTITY_FETCH_MAP = {}

function deleteEntityFetchVariable(type) {
  delete ENTITY_FETCH_MAP[type]
}

export function mapToCatalogSearch(value, type, state) {
  return (dispatch) => {
    const queryPresent = value !== ''
    // TODO: Verify if menus have search endpoint now?
    // menus don't have a search function so return early
    if (type === ROUTES.menus.name) return
    if (queryPresent) {
      dispatch(searchCatalogEntity(value, type, state))
    } else {
      // If there isn't a query present, just fetch normally
      dispatch(mapToCatalogFetch(type))
    }
  }
}

export function mapToCatalogFetch(type) {
  return (dispatch) => {
    if (type === SPECIES_CATALOG_TYPE) return dispatch(fetchSpecies()) // species is the only outlier

    dispatch(fetchCatalogEntity(type))
  }
}

export function mapToCatalogSort(value, type) {
  return (dispatch) => {
    dispatch(setCatalogSort(value, type))
  }
}

/**
 * Checks src/pages/catalog/routes for supported index query params and
 * their default values, and merges the received query params with the defaults
 * @param {Object} reduxState - current redux state object
 * @param {string} routeName - e.g. 'products', 'menus'
 * @param {Object} queryParams - params received to be merged with defaults
 * @returns {Object} query params to supply to API request
 */
function createIndexQueryParams(reduxState = {}, routeName, queryParams = {}) {
  if (routeName) {
    const { indexQueryParams = {} } = ROUTES[routeName]
    const mergedParams = deepmerge(indexQueryParams, queryParams)

    if (Object.keys(indexQueryParams).includes('stateIds')) {
      const stateShortname = getCatalogStateFilter(reduxState)
      if (stateShortname) mergedParams.stateIds = stateShortname
    }

    if (Object.keys(indexQueryParams).includes('is_ecommerce')) {
      const ecommerceFilterValue = getCatalogEcommerceFilter(reduxState)
      if (ecommerceFilterValue !== 'All') {
        mergedParams.is_ecommerce = ecommerceFilterValue
      }
    }

    const apiIndexQueryParams = Object.keys(mergedParams).reduce(
      (validParams, paramName) => {
        const paramSupported =
          Object.keys(indexQueryParams).includes(paramName)
        const paramValue = mergedParams[paramName]
        if (paramSupported && paramValue !== null) {
          validParams[paramName] = paramValue
        }
        return validParams
      },
      {}
    )
    return apiIndexQueryParams
  }
}

export function fetchCatalogProducts() {
  return async (dispatch, getState) => {
    dispatch(requestProducts())

    const route = ROUTES.products
    const queryParams = createIndexQueryParams(getState(), route.name)
    const { err, data } = await api.getAllCatalogItems(queryParams)
    dispatch(receiveProducts())
    if (err) return console.error(err)

    const items = dedupeArrayByObjectKey(data, 'id')
    const { productsObject, productIds } = arrayToObject(items)

    dispatch(setProducts(productsObject))
    dispatch(setCatalogProducts(productIds))
  }
}

export function fetchCatalogBrands() {
  return async (dispatch, getState) => {
    dispatch(requestCatalog())

    const route = ROUTES.brands
    const queryParams = createIndexQueryParams(getState(), route.name)

    // Check if queryParams is a non-empty object (has is_ecommerce property)
    const hasQueryParams = queryParams && Object.keys(queryParams).length > 0

    // Call the API with or without queryParams
    const { err, data } = hasQueryParams ? await api.getAllCatalogBrands(queryParams) : await api.getAllCatalogBrands()

    dispatch(receiveCatalog())
    if (err) return console.error(err)
    dispatch(setCatalogBrands(data))
    dispatch(createBrandMap(data))
  }
}

export function fetchCatalogEntity(type) {
  return async (dispatch, getState) => {
    const route = ROUTES[type]
    if (route.name === ROUTES.products.name) {
      return dispatch(fetchCatalogProducts())
    }
    if (route.name === ROUTES.brands.name) {
      return dispatch(fetchCatalogBrands())
    }

    const entityObj = ENTITY_MAP[type]

    dispatch(requestCatalog())

    const queryParams = createIndexQueryParams(getState(), route.name)
    const apiRequest = entityObj.fetch
    const { err, data } = await apiRequest(queryParams)
    // if the ENTITY_FETCH_MAP has a record for this request, delete it
    if (ENTITY_FETCH_MAP[type]) {
      deleteEntityFetchVariable(type)
    }
    dispatch(receiveCatalog())
    if (err) return console.error(err)
    dispatch(entityObj.set(data))

    if (entityObj.postFetch) {
      dispatch(entityObj.postFetch(data))
    }

    // set variable of fetch so we can cancel an in-flight request if needed
    ENTITY_FETCH_MAP[type] = apiRequest
  }
}

export function fetchSpecies() {
  return async (dispatch) => {
    dispatch(requestSpecies())

    const { err, data } = await api.getCatalogSpecies()
    dispatch(receiveSpecies())
    if (err) return console.error(err)

    const speciesMap = {}
    data.forEach(function (species) {
      speciesMap[species.id] = species
    })

    dispatch(clearSpeciesMap())
    dispatch(setSpeciesMap(speciesMap))

    dispatch(setCatalogSpecies(data))
  }
}

export function fetchLocationStates() {
  return async (dispatch) => {
    const { err, data } = await api.getLocationStates()

    const filteredStates = data.filter((d) =>
      AVAILABLE_STATES.includes(d.state)
    )
    if (err) return console.error(err)

    dispatch(setLocationStates(filteredStates))
  }
}

// gets catalog item by id, and adds it to the product list
export function fetchAndSetCatalogItem(id) {
  return async (dispatch, getState) => {
    dispatch(requestProducts())
    const state = getState()
    const { err, data } = await api.getCatalogItem({ id })
    dispatch(receiveProducts())
    if (err) return console.error(err)
    const {
      catalog: { products }
    } = state

    dispatch(setProduct(data, data.id))
    dispatch(setCatalogProducts([...products, data.id]))
  }
}

/**
 * Test if input value is possibly a catalog id.
 * Catalog ids seemingly have this pattern: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
 * We make a guess that receiving an input value that matches this pattern assumes a catalog id.
 **/
function isCatalogId(value) {
  const idRegex = /^\w{8}(?:-\w{4}){3}-\w{12}$/im
  return Boolean(value.trim().match(idRegex))
}

/**
 *  searchCatalog uses api.getCatalogItem and attempts to look up an
 *  item by ID first. If there is no result then searchCatalog will pass
 *  the value variable to the query parameter of gateway.searchCatalog()
 */
export function searchCatalog(query, type, state) {
  return async (dispatch) => {
    if (!query) {
      return
    }

    try {
      if (type === 'products') {
        dispatch(requestProducts())

        let data, items
        if (isCatalogId(query)) {
          // search by catalogId
          ({ data } = await api.getCatalogItem({ id: query }))
          items = [data]
        } else {
          // general search
          ({ data } = await gatewayClient().searchCatalog({
            query,
            type,
            state
          }))
          items = dedupeArrayByObjectKey(data.items.slice(0, 50), 'id')
        }

        dispatch(receiveProducts())
        const { productsObject, productIds } = arrayToObject(items)
        dispatch(setProducts(productsObject))
        dispatch(setCatalogProducts(productIds))
      } else {
        dispatch(requestCatalog())
        const { data } = await gatewayClient().searchCatalog({
          query,
          type,
          state
        })
        dispatch(receiveCatalog())
        const entityObj = ENTITY_MAP[type]
        dispatch(entityObj.set(data.items))
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export function searchCatalogEntity(value, type, state) {
  return async (dispatch) => {
    return dispatch(searchCatalog(value, type, state))
  }
}

export function setCatalogProducts(products) {
  return {
    type: SET_CATALOG_PRODUCTS,
    payload: products
  }
}

export function setCatalogMenus(menus) {
  return {
    type: SET_CATALOG_MENUS,
    menus
  }
}

export function setCatalogGroups(groups) {
  return {
    type: SET_CATALOG_GROUPS,
    payload: groups
  }
}

export function setCatalogBrands(brands) {
  return {
    type: SET_CATALOG_BRANDS,
    brands
  }
}

function setCatalogSubtypes(types) {
  return {
    type: SET_CATALOG_SUBTYPES,
    types
  }
}

function setCatalogStrains(strains) {
  return {
    type: SET_CATALOG_STRAINS,
    strains
  }
}

function setCatalogSpecies(species) {
  return {
    type: SET_CATALOG_SPECIES,
    species
  }
}

function setLocationStates(statesAvailable) {
  return {
    type: SET_LOCATION_STATES,
    statesAvailable
  }
}

function setCatalogTypes(types) {
  return {
    type: SET_CATALOG_TYPES,
    types
  }
}

export function setCatalogSort(sort, typeToSort) {
  return {
    type: SET_CATALOG_SORT,
    sort,
    typeToSort
  }
}

export function setProductConfirmDrawerReasons(reasons) {
  return {
    type: SET_PRODUCT_CONFIRM_DRAWER_REASONS,
    reasons
  }
}

export function showProductConfirmDrawer() {
  return {
    type: SHOW_PRODUCT_CONFIRM_DRAWER
  }
}

export function hideProductConfirmDrawer() {
  return {
    type: HIDE_PRODUCT_CONFIRM_DRAWER
  }
}

export function confirmSaveProduct(routerLocation) {
  return function (dispatch) {
    dispatch(submitCatalogItem(routerLocation))
    dispatch(hideProductConfirmDrawer())
  }
}

/**
 * Sets catalog state filter in redux using the onChange event received, then
 * refetches all indexes that support the state filter
 * @export
 * @async
 * @function
 * @param {object} event - target.value -> stateShortname - e.g. 'CA', 'WA'
 * @returns {null}
 */
export function applyCatalogStateFilter({ target = {} }) {
  return async (dispatch) => {
    let { value: stateShortname = 'All' } = target
    if (stateShortname) {
      if (stateShortname === 'All') stateShortname = '' // clear the filter

      const routesWithStateFiltering = Object.values(ROUTES).reduce(
        (routes, route) => {
          const statePresent = Object.keys(route.indexQueryParams).includes(
            'stateIds'
          )
          if (statePresent) routes.push(route.name)
          return routes
        },
        []
      )

      dispatch(setCatalogStateFilter(stateShortname))

      // Refetch any index that supports state filtering
      routesWithStateFiltering.forEach((route) => {
        dispatch(fetchCatalogEntity(route))
      })
    }
  }
}

export function applyCatalogEcommerceFilter({ target = {} }) {
  return async (dispatch) => {
    const { value: isEcommerceFilterValue } = target

    const routesWithEcommerceFiltering = Object.values(ROUTES).reduce(
      (routes, route) => {
        const isEcommerce = Object.keys(route.indexQueryParams).includes(
          'is_ecommerce'
        )
        if (isEcommerce) routes.push(route.name)
        return routes
      },
      []
    )

    dispatch(setCatalogEcommerceFilter(isEcommerceFilterValue))

    // Refetch any index that supports state filtering
    routesWithEcommerceFiltering.forEach((route) => {
      dispatch(fetchCatalogEntity(route))
    })
  }
}

/**
 * If defined, will be passed as a query param when fetching catalog
 * items (a.k.a products), groups, or menus (via `endpoint?stateIds='CA'`);
 * @export
 * @function
 * @param {string} stateShortname - 'CA'
 * @returns {null}
 */
export function setCatalogStateFilter(stateShortname) {
  return {
    type: SET_CATALOG_STATE_FILTER,
    catalogStateFilter: stateShortname
  }
}

export function setCatalogEcommerceFilter(isEcommerceFilterValue) {
  return {
    type: SET_CATALOG_ECOMMERCE_FILTER,
    catalogEcommerceFilter: isEcommerceFilterValue
  }
}

function createBrandMap(data) {
  return async (dispatch) => {
    const brandsMap = {}
    data.forEach(function (brand) {
      brandsMap[brand.id] = brand
    })

    dispatch(clearBrandsMap())
    dispatch(setBrandsMap(brandsMap))
  }
}
