import React, { PureComponent } from 'react'
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string
} from 'prop-types'

import history from 'components/router/history'
import Screen from 'src/components/screen/container'
import RightBar from 'src/components/right-bar'
import Overlay from 'src/components/overlay'
import FabFan from 'src/components/fab-fan'
import ConfirmDrawer from 'src/components/confirm-drawer'

import NavigationBar from './navigation-bar'
import EditProduct from './edit/product'
import EditType from './edit/type'
import EditSubtype from './edit/subtype'
import EditStrain from './edit/strain'

import ProductList from './list/product-list'
import BrandList from './list/brand-list'
import GroupList from './list/group-list'
import MenuList from './list/menu-list'
import StrainList from './list/strain-list'
import TypeList from './list/type-list'
import SubtypeList from './list/subtype-list'

import CATALOG_ROUTES from 'src/pages/catalog/routes'
import MC_ROUTES from 'src/components/router/routes'
import CatalogAPIControls from './CatalogAPIControls'

export class Catalog extends PureComponent {
  static propTypes = {
    activeDispensaries: arrayOf(object),
    skippedDepots: array,
    resetSkippedDepots: func,
    applyCatalogStateFilter: func,
    applyCatalogEcommerceFilter: func,
    brands: arrayOf(object),
    cantCreateStrain: bool,
    catalogSort: object,
    catalogStrain: object,
    confirmSaveProduct: func,
    createCatalogStrain: func,
    createInventoryItems: func,
    createOrUpdateGroup: func,
    currentRoute: string,
    currentStateFilter: string,
    currentIsEcommerceFilter: oneOfType([bool, string]),
    deleteCatalogStrain: func,
    fetchCatalogFn: func,
    groups: arrayOf(object),
    hideProductConfirmDrawer: func,
    hideProductForm: func,
    hideStrainForm: func,
    hideSubtypeForm: func,
    hideTypeForm: func,
    isActionBarOpen: bool,
    isProductConfirmDrawerOpen: bool,
    isProductFormOpen: bool,
    isStrainFormOpen: bool,
    isSubtypeFormOpen: bool,
    isTypeFormOpen: bool,
    menus: array,
    pathname: string,
    productConfirmDrawerReasons: array,
    products: arrayOf(object),
    pushNotification: func,
    resetBrandItem: func,
    resetGroupItem: func,
    resetMenuItem: func,
    searchCatalogFn: func,
    setCatalogSortFn: func,
    setCatalogStrain: func,
    showProductForm: func,
    showStrainForm: func,
    showSubtypeForm: func,
    showTypeForm: func,
    species: arrayOf(object),
    statesAvailable: arrayOf(shape({ state: string })),
    strainsSorted: arrayOf(object),
    subtypesSorted: arrayOf(object),
    toggleActionBar: func,
    types: arrayOf(object),
    typesSorted: arrayOf(object),
    updateCatalogStrain: func,
    updateGroupObject: func,
    uploadPublicFile: func
  };

  moveToCreate = (path) => {
    const { resetBrandItem, resetGroupItem, resetMenuItem, toggleActionBar } =
      this.props

    resetBrandItem()
    resetGroupItem()
    resetMenuItem()
    toggleActionBar()

    history.push(path)
  };

  state = {
    fabOpts: [
      {
        name: 'Create Strain',
        onClick: this.props.showStrainForm,
        background: '#C14890'
      },
      {
        name: 'Create Subtype',
        onClick: this.props.showSubtypeForm,
        background: '#3DD2DD'
      },
      {
        name: 'Create Type',
        onClick: this.props.showTypeForm,
        background: '#1EA8D2'
      },
      {
        name: 'Create Menu',
        onClick: () => this.moveToCreate(MC_ROUTES.ADD_CATALOG_MENU),
        background: '#EDBB33'
      },
      {
        name: 'Create Brand',
        onClick: () => this.moveToCreate(MC_ROUTES.ADD_CATALOG_BRAND),
        background: '#B53553'
      },
      {
        name: 'Create Group',
        onClick: () => this.moveToCreate(MC_ROUTES.ADD_CATALOG_GROUP),
        background: '#35BEFF'
      },
      {
        name: 'Create Product',
        onClick: this.props.showProductForm,
        background: '#FFF'
      }
    ]
  };

  renderList = () => {
    const {
      activeDispensaries,
      brands,
      createInventoryItems,
      createOrUpdateGroup,
      currentRoute,
      fetchCatalogFn,
      groups,
      menus,
      pathname,
      products,
      pushNotification,
      showProductForm,
      showStrainForm,
      showSubtypeForm,
      showTypeForm,
      species,
      strainsSorted,
      subtypesSorted,
      types,
      typesSorted,
      updateGroupObject,
      skippedDepots,
      resetSkippedDepots
    } = this.props

    switch (currentRoute) {
      case CATALOG_ROUTES.products.name:
        return (
          <ProductList
            activeDispensaries={activeDispensaries}
            skippedDepots={skippedDepots}
            resetSkippedDepots={resetSkippedDepots}
            create={showProductForm}
            createInventoryItems={createInventoryItems}
            pathname={pathname}
            products={products}
            pushNotification={pushNotification}
          />
        )

      case CATALOG_ROUTES.brands.name:
        return (
          <BrandList
            brands={brands}
            create={() => this.moveToCreate(MC_ROUTES.ADD_CATALOG_BRAND)}
          />
        )

      case CATALOG_ROUTES.groups.name:
        return (
          <GroupList
            create={() => this.moveToCreate(MC_ROUTES.ADD_CATALOG_GROUP)}
            createOrUpdateGroup={createOrUpdateGroup}
            fetchCatalogFn={fetchCatalogFn}
            groups={groups}
            updateGroupObject={updateGroupObject}
          />
        )

      case CATALOG_ROUTES.menus.name:
        return (
          <MenuList
            create={() => this.moveToCreate(MC_ROUTES.ADD_CATALOG_MENU)}
            menus={menus}
          />
        )

      case CATALOG_ROUTES.strains.name:
        return (
          <StrainList
            create={showStrainForm}
            fetchCatalogFn={fetchCatalogFn}
            haveFetchedSpecies={species.length}
            pathname={pathname}
            strainsSorted={strainsSorted}
          />
        )

      case CATALOG_ROUTES.subtypes.name:
        return (
          <SubtypeList
            create={showSubtypeForm}
            fetchCatalogFn={fetchCatalogFn}
            haveFetchedTypes={types.length}
            pathname={pathname}
            subtypesSorted={subtypesSorted}
          />
        )

      case CATALOG_ROUTES.types.name:
        return (
          <TypeList
            create={showTypeForm}
            pathname={pathname}
            typesSorted={typesSorted}
          />
        )

      default:
        // never here..
        return null
    }
  };

  getOverlayOnClick = () => {
    const {
      hideProductForm,
      hideStrainForm,
      hideSubtypeForm,
      hideTypeForm,
      isProductFormOpen,
      isStrainFormOpen,
      isSubtypeFormOpen,
      isTypeFormOpen
    } = this.props

    if (isProductFormOpen) {
      return hideProductForm
    }
    if (isStrainFormOpen) {
      return hideStrainForm
    }
    if (isSubtypeFormOpen) {
      return hideSubtypeForm
    }
    if (isTypeFormOpen) {
      return hideTypeForm
    }
  };

  render() {
    const {
      applyCatalogStateFilter,
      applyCatalogEcommerceFilter,
      cantCreateStrain,
      catalogSort,
      catalogStrain,
      confirmSaveProduct,
      createCatalogStrain,
      currentRoute,
      currentStateFilter,
      deleteCatalogStrain,
      fetchCatalogFn,
      hideProductConfirmDrawer,
      isActionBarOpen,
      isProductConfirmDrawerOpen,
      isProductFormOpen,
      isStrainFormOpen,
      isSubtypeFormOpen,
      isTypeFormOpen,
      productConfirmDrawerReasons,
      searchCatalogFn,
      setCatalogSortFn,
      setCatalogStrain,
      species,
      statesAvailable,
      toggleActionBar,
      updateCatalogStrain,
      uploadPublicFile,
      currentIsEcommerceFilter
    } = this.props

    const { fabOpts } = this.state
    const isOverlayOpen =
      isProductFormOpen ||
      isStrainFormOpen ||
      isSubtypeFormOpen ||
      isTypeFormOpen
    const overlayOnClick = isOverlayOpen ? this.getOverlayOnClick() : null

    return (
      <Screen>
        <NavigationBar
          currentRoute={currentRoute}
          fetchCatalogFn={fetchCatalogFn}
        />

        <CatalogAPIControls
          availableStates={statesAvailable}
          currentCatalogSorting={catalogSort}
          currentRoute={currentRoute}
          currentStateFilter={currentStateFilter}
          currentIsEcommerceFilter={currentIsEcommerceFilter}
          setCatalogSearch={searchCatalogFn}
          setCatalogSorting={setCatalogSortFn}
          setCatalogStateFilter={applyCatalogStateFilter}
          setCatalogEcommerceFilter={applyCatalogEcommerceFilter}
        />

        {this.renderList()}

        <Overlay isOpen={isOverlayOpen} onClick={overlayOnClick} />

        <FabFan
          open={isActionBarOpen}
          options={fabOpts}
          onClick={toggleActionBar}
        />

        <RightBar isOpen={isProductFormOpen}>
          <EditProduct {...this.props} />
        </RightBar>

        <RightBar isOpen={isTypeFormOpen}>
          <EditType {...this.props} />
        </RightBar>

        <RightBar isOpen={isSubtypeFormOpen}>
          <EditSubtype {...this.props} />
        </RightBar>

        <RightBar isOpen={isStrainFormOpen}>
          <EditStrain
            cantCreateStrain={cantCreateStrain}
            catalogStrain={catalogStrain}
            createCatalogStrain={createCatalogStrain}
            deleteCatalogStrain={deleteCatalogStrain}
            isStrainFormOpen={isStrainFormOpen}
            setCatalogStrain={setCatalogStrain}
            species={species}
            updateCatalogStrain={updateCatalogStrain}
            uploadPublicFile={uploadPublicFile}
          />
        </RightBar>

        <ConfirmDrawer
          confirmMessages={productConfirmDrawerReasons}
          showDrawer={isProductConfirmDrawerOpen}
          handleConfirm={confirmSaveProduct}
          handleCancel={hideProductConfirmDrawer}
        />
      </Screen>
    )
  }
}

export default Catalog
