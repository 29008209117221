import t from './actionTypes'
import { setDepots } from 'src/redux/depots-shared-redux/actions'
import api from 'api'
import gatewayClient from 'src/gateway-client'

import {
  requestDispensaries,
  receiveDispensaries,
  receiveDrivers,
  requestDrivers
} from 'src/redux/loading/actions'
import { updateDriverMap } from 'src/redux/driver-map/actions'
import { getDriverMap } from 'src/redux/driver-map/selectors'
import {
  formatDepotDriversMap,
  fetchDriversByDepot
} from 'src/redux/driver-map/operations'
import { setDepotFilter } from 'src/redux/deliveries/actions'
import { setDepotsMap, clearDepotsMap } from 'src/redux/depots-map/actions'
import errorHandler from 'helpers/error-handler'

export function fetchAllDispensaries () {
  return async dispatch => {
    dispatch(requestDispensaries())
    try {
      const { data: { dispensaries } } = await gatewayClient().getEnabledDispensaries()

      const dispensariesMap = {}
      dispensaries.forEach(function (dispensary) {
        dispensariesMap[dispensary.id] = dispensary
      })

      // flatten each dispensaries enabled depots
      const depots = dispensaries.reduce((acc, dispensary) => acc.concat(dispensary.depots.flat()), [])

      const depotsMap = {}
      depots.forEach(function (depot) {
        depotsMap[depot.id] = depot
      })
      dispatch(clearDepotsMap())
      dispatch(setDepotsMap(depotsMap))

      dispatch(setDispensaries(dispensaries))
      dispatch(setDispensariesMap(dispensariesMap))
      dispatch(setDepots(depots))
    } catch (error) {
      errorHandler(error)
      return console.error(error)
    } finally {
      dispatch(receiveDispensaries())
    }
  }
}

// TODO: Deprecate me. I do too much and thrash the network. Fetch depot drivers seperately.
export function fetchDispensariesAndDepotDrivers (selectedDepotId) {
  return async dispatch => {
    dispatch(requestDispensaries())
    let depots
    try {
      const { data: { dispensaries } } = await gatewayClient().getEnabledDispensaries()

      const dispensariesMap = {}
      dispensaries.forEach(function (dispensary) {
        dispensariesMap[dispensary.id] = dispensary
      })

      // flatten each dispensaries enabled depots
      depots = dispensaries.reduce((acc, dispensary) => acc.concat(dispensary.depots.flat()), [])

      const depotsMap = {}
      depots.forEach(function (depot) {
        depotsMap[depot.id] = depot
      })
      dispatch(clearDepotsMap())
      dispatch(setDepotsMap(depotsMap))

      dispatch(setDispensaries(dispensaries))
      dispatch(setDispensariesMap(dispensariesMap))
      dispatch(setDepots(depots))
    } catch (error) {
      errorHandler(error)
      return console.error(error)
    }

    dispatch(receiveDispensaries())

    if (selectedDepotId) {
      const depotName = depots.find(depot => depot.id === selectedDepotId).name
      dispatch(setDepotFilter([{ key: 'depotName', value: depotName }]))
      dispatch(fetchDriversByDepot(selectedDepotId))
      return
    }

    const depotFetchFns = depots.map(depot => {
      if (depot.id === selectedDepotId) {
        dispatch(setDepotFilter([{ key: 'depotName', value: depot.name }]))
      }
      return dispatch(fetchDriversByDepot(depot.id))
    })

    // returning Promise.all without a callback so consumers can hook into
    // the completion of all of the fetches and decide how to react
    return Promise.all(depotFetchFns)
  }
}

function setDispensaries (dispensaries) {
  return {
    type: t.SET_DISPENSARIES,
    dispensaries
  }
}

function setDispensariesMap (dispensariesMap) {
  return {
    type: t.SET_DISPENSARIES_MAP,
    dispensariesMap
  }
}

export function fetchSpecificDepotDrivers (id) {
  return async (dispatch, getState) => {
    const state = getState()
    dispatch(requestDrivers())

    const { err, data } = await api.getDepotDrivers({ id })
    dispatch(receiveDrivers())
    if (err) {
      errorHandler(err)
      return console.error(err)
    }

    const driverMap = formatDepotDriversMap(data, getDriverMap(state))
    dispatch(updateDriverMap(driverMap))
  }
}

export function fetchSpecificDispensaryDrivers (id) {
  return async (dispatch, getState) => {
    const state = getState()
    dispatch(requestDrivers())
    const { err, data } = await api.getDispensaryDrivers({ id })
    dispatch(receiveDrivers())
    if (err) {
      errorHandler(err)
      return console.error(err)
    }

    const driverMap = formatDepotDriversMap(data, getDriverMap(state))
    dispatch(updateDriverMap(driverMap))
  }
}

export function clearDispensariesStore () {
  return {
    type: t.CLEAR_DISPENSARIES_STORE
  }
}
